import { useSharedPromoData } from '@/core/hooks';
import { LANG } from '@/core/i18n';
import { HEADER_PATH } from '../../constants';
import CommonMenuItem from '../common-menu';
import Menu from '../menu';

export default function PartnerShipMenu({ isActive }: { isActive: boolean }) {
  const { luckydraw, assist, mysterybox } = useSharedPromoData();
  const LuckyDrawMenu = {
    name: LANG('幸运转盘'),
    tips: LANG('邀请好友，领{num}现金劵，可提现', { num: luckydraw?.prizeValue || 0 }),
    href: HEADER_PATH.LUCKY_WHEEL,
    newTag: true,
  };
  const InviteMenu = {
    name: LANG('助力领劵'),
    tips: LANG('邀请{num}位好友，您和好友都有奖', { num: assist?.requiredInvites || 0 }),
    href: HEADER_PATH.INVITE_FRIENDS,
    newTag: true,
  };
  const MysteryBoxMenu = {
    name: LANG('邀请盲盒'),
    tips: LANG('邀请{num}位好友，赢取双重奖励', { num: 1 }),
    href: HEADER_PATH.MYSTERY_BOX,
    newTag: true,
  };
  const menuList = [
    {
      name: LANG('全球合伙人'),
      tips: LANG('将你的流量转换为加密货币佣金'),
      href: HEADER_PATH.AFFILIATE,
    },

    { name: LANG('成为BYDFI ARMY'), tips: LANG('推广BYDFI并获得奖励'), href: HEADER_PATH.ARMY },
  ];
  if (luckydraw?.enable) {
    menuList.push(LuckyDrawMenu);
  }
  if (assist?.enable) {
    menuList.push(InviteMenu);
  }
  if (mysterybox?.enable) {
    menuList.push(MysteryBoxMenu);
  }
  const calculateMenuLength = () => {
    switch (menuList.length) {
      case 3:
        return '230px';
      case 4:
        return '300px';
      case 5:
        return '364px';
      default:
        return '160px';
    }
  };
  return (
    <Menu
      showArrow
      width='235px'
      height={calculateMenuLength()}
      position='left:0'
      newTag
      content={<CommonMenuItem menuList={menuList} />}
      isActive={isActive}
    >
      {LANG('邀请计划')}
    </Menu>
  );
}
